import React, { FC, ReactNode, Fragment } from "react";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { Script } from "gatsby";
import { query } from "../data/graphql/query";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-default";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const data = query();

  const name = data.company.edges[0].node.childMetadatasJson.companyName;
  const slogan = data.company.edges[0].node.childMetadatasJson.slogan;
  const city = data.company.edges[0].node.childMetadatasJson.cidade;
  const logoTopbar = getImage(data.logoTopbar.edges[0].node.gatsbyImageData)!;
  const logoFooter = getImage(data.logoFooter.edges[0].node.gatsbyImageData)!;
  const GA4 = data.analytics.edges[0].node.childMetadatasJson.ga;
  const facebook = data.redes.edges[0].node.childMetadatasJson.facebook;
  const instagram = data.redes.edges[0].node.childMetadatasJson.instagram.user;
  const landingSubtitle =
    data.company.edges[0].node.childMetadatasJson.subtitle;

  const h2Text =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses.h2 ||
    "Cuidando do seu pet com carinho!";

  const getGatsbyImageNode = (
    image: IGatsbyImageData,
    alt: string,
    isContained?: boolean
  ) => (
    <GatsbyImage
      image={image}
      alt={alt}
      loading="eager"
      style={{ width: "100%", height: "100%" }}
      objectFit={isContained ? "contain" : "cover"}
    />
  );

  // console.log("ID DA MÉTRICA GA4:", process.env.GATSBY_GA_MEASUREMENT_ID);

  return (
    <Fragment>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${GA4}', { send_page_view: false })`,
        }}
      />
      <LayoutLibrary
        logoTopbar={getGatsbyImageNode(logoTopbar, `Logo ${name}`, true)}
        logoFooter={getGatsbyImageNode(logoFooter, `Logo ${name}`, true)}
        companyName={name}
        city={city}
        slogan={landingSubtitle}
        badge={{ type: "vet" }}
        facebook={facebook}
        instagram={instagram}
        navItems={[
          {
            label: "Home",
            path: "/",
          },
          {
            label: "Serviços",
            path: "/servicos",
          },
          {
            label: "Galeria",
            path: "/galeria",
          },
          {
            label: "Contato",
            path: "/contato",
          },
        ]}
      >
        {children}
      </LayoutLibrary>
    </Fragment>
  );
};

export default Layout;
